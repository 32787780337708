import MemberService from "@/views/customer/memberService.vue";

export default {
    head:{
        hotWire: "24小时服务热线",
        home:'首页',
        about:'关于我们',
        medicial:'医疗健康',
        customer:'客户服务',
        teach:'科研教学',
        education:'科普教育',
        news:'新闻中心',
        connect:'联系我们',
        medicial01:'整合医疗',
        medicial02:'服务特色',
        medicial03:'科室介绍',
        medicial04:'专家介绍',
        medicial05:'智慧健康',
        medicial06:'健康体检',
        medicial07:'体检套餐',
        medicial08:'主动健康创新中心',
        medicial09:'精准医学',
        medicial10:'伦理委员会',
        medicial11:'研究院',
        medicial12:'生物样本库',
        medicial13:'特色服务',
        medicial14:'肿瘤精准个性化综合诊疗中心',

    },
    footer:{
        link_left01:'关注玄合元门诊部公众号',
        link_left02:'预约·挂号·查询报告',
        menu_item01:'关于我们',
        menu_item011:'企业概况',
        menu_item012:'发展历程',
        menu_item013:'荣誉资质',
        menu_item02:'医疗健康',
        menu_item021:'整合医疗',
        menu_item022:'智慧健康',
        menu_item023:'精准医学',
        menu_item024:'特色服务',
        menu_item03:'客户服务',
        menu_item031:'会员服务',
        menu_item032:'商保服务',
        menu_item04:'新闻中心',
        menu_item041:'玄合动态',
        menu_item042:'社会公益',
        menu_item05:'联系我们',
        menu_item051:'联系我们',
        menu_item052:'加入我们',
        copyright01:'上海玄合元门诊部有限公司',
    },
    // 关于我们
    aboutUs: {
        welcome: "你好，世界",
        tabList: [
            { key: 'overview', value: '企业概况' },
            { key: 'founders', value: '创始人介绍' },
            { key: 'development', value: '发展历程' },
            { key: 'honor', value: '荣誉资质' },
        ],
        intro: "企业简介",
        briefInformation: "玄合医疗于2019年8月8日在上海浦东张江国际医学园区成立。由国内肿瘤领域著名医学专家丁罡教授创建，秉承“仁爱、诚信、创新、追求卓越、社会责任感”的价值观，致力于科技创新、管理变革、服务升级，整合国际先进生物医药技术、医疗管理、互联网技术、患者教育等资源，与国内多家医疗机构合作共建肿瘤精准诊疗中心，开展肿瘤多学科一体化精准综合治疗和学科建设与管理。同时创建国内患者教育中心，提供中国居民的肿瘤防治教育。",
        business: "成熟优势业务",
        businessInformation: "玄合医疗旗下的丁罡肿瘤医生集团是目前国内最早、最大规模专注难治性肿瘤多学科综合诊治的临床转化医疗集团。团队专注于肿瘤影像、病理、手术、放疗、内科、微创介入、重症、心理、中医药、康复护理、营养和临床试验等专科医疗服务，擅长复发转移肿瘤的多学科综合诊治，特别是难治和重症肿瘤的精准个性化治疗。",
        health: "主动健康创新业务",
        healthInformation:"随着人口老龄化，玄合医疗开始拓展崭新业务，致力于中国百姓智慧健康全生命周期管理，特别在老年社区与家庭主动健康管理领域，开启数智健康科技发展和创新。企业秉持着“主动预防，科技引领，健康生活”的理念，致力于将最先进的科技应用于健康领域，帮助人们实现更健康、更长寿、更高质量的生活，让健康触手可及，主动健康创新业务范围包括：\n" +
            "1. 智能穿戴设备：开发和生产具有实时监测、数据分析功能的智能手环、智能手表等穿戴设备，帮助用户实时了解身体状况，预防疾病。\n" +
            "\n" +
            "2. 健康管理平台：提供个性化的健康管理方案，包括营养饮食、运动健身、心理健康等方面的建议和指导，帮助用户建立健康的生活方式。\n" +
            "\n" +
            "3. 远程医疗服务：利用互联网和移动医疗技术，为用户提供线上问诊、药品配送、健康咨询等一站式服务，方便用户随时随地获取医疗服务。\n" +
            "\n" +
            "4.社区队列研究：针对浦东周浦镇社区慢病人群，开展队列研究。整合医学院校、医疗机构、生物医药企业、政府机构等资源，开展社区慢病健康管理，健康教育，流行病研究，预防干预。建立样本库，数据库，细胞库和基因库等生物医药科研平台。",
        technology:'技术创新',
        technologyInformation:'玄合医疗始终坚持以技术创新为核心竞争力，不断投入研发资源，推动健康科技的创新发展。企业拥有多项国内外专利，其技术成果在行业内具有广泛的影响力。2024年5月18日与美国马里兰州蒙特马利郡合作，共建国际生物医药转化中心。',
        society:'社会责任',
        societyInformation:'玄合医疗积极履行社会责任，致力于提高公众健康意识和水平。定期举办健康科普讲座、公益活动，普及健康知识，帮助更多人建立健康的生活方式。同时，企业还关注弱势群体的健康问题，为他们提供力所能及的帮助和支持。玄合医疗是上海市浦东新区癌症康复促进会的会长单位。上海市癌症康复俱乐部的医疗服务副会长单位。',
        future:'未来展望',
        futureInformation:'未来，玄合医疗将继续秉承“主动预防，科技引领，健康生活”的理念，不断创新和发展健康科技，为人们提供更加便捷、高效、个性化的健康服务。企业还将积极拓展国际市场，与全球合作伙伴共同推动健康科技的全球发展，为人类健康事业做出更大的贡献。',
        company_title01:'致力于创建研究型、智慧型综合门诊部',
        company_title02:'丁罡肿瘤医生集团',
        company_title03:'主动预防，科技引领，健康生活',
        company_text01:'专注于家庭与社区数字健康创新',
        company_text02:'为百姓提供临床诊疗、健康管理、科研教育、社区科普等全方位、全周期健康服务',
        company_text03:'专注于肿瘤影像、病理、手术、放疗、内科、微创介入、',
        company_text04:'重症、心理、中医药、康复护理、营养和临床试验等专科医疗服务',
        company_text05:'擅长难治、重症、复发转移肿瘤的多学科综合、精准个性化诊治',
        company_text06:'将最先进的科技应用于健康领域',
        company_text07:'帮助人们更健康、更长寿、更高质量地生活',
        company_doctor01:'主任医师',
        company_doctor02:'全职专科医护人员',
        company_doctor03:'兼职肿瘤专家',
        profile:'丁罡 玄合医疗创始人 首席科学家',
        profile_dec01:'医学科学博士，主任医师，教授',
        profile_dec02:'上海交大博士生导师',
        profile_dec03:'CEIBS中欧 EMBA',
        profile_dec04:'南京医科大学卫生管理硕士生导师',
        profile_dec05:'25年肿瘤专科临床工作、15年医院管理岗位。',
        profile_dec06:'历任复旦肿瘤医院、仁济医院、新华医院、上海国际医学中心肿瘤科主任，副院长',
        profileadv:'擅长',
        profileadv01:'擅长常见肿瘤疾病个性化精准诊疗方案的制定，特别是肿瘤的复发转移、疑难重症的综合处理。',
        profileadv02:'擅长医院的学科建设和综合管理。',
        profilemain:'主要工作业绩',
        profilemain01:'探索疾病发展新机制：国际上首次提出并验证“神经→肿瘤”假说，明确该过程的心理生理学机制。全球范围内首个针对肿瘤神经调控研究领域的创新性研究。',
        profilemain02:'开创临床肿瘤诊疗新路径。创建难治性肿瘤多学科精准个性化诊疗新模式。',
        profilemain03:'创建“肿瘤患者 5H 创新医疗服务模式”（Hospital 专业、Hotel 舒适、Home 温馨、Heart 用心、Happiness 满意）”。荣获上海医疗服务品牌和2022第五届“上海医改十大创新措施”提名奖。',
        profilemain04:'积极开展科普公益项目，2022年荣获第一轮上海市健康科普引领人才专项。',
        profilejob:'学术任职',
        profilejob01:'中国抗癌协会康复分会副主任委员',
        profilejob02:'中国医学装备协会健康管理分会副会长',
        profilejob03:'中国神经科学学会理事.感觉与运动分会副主任委员',
        profilejob04:'中国生理学会疼痛转化研究专业委员会副主任委员',
        profilejob05:'中国研究型医院学会社会办医分会副会长',
        profilejob06:'上海市医师协会肿瘤专科分会常委，秘书',
        profilejob07:'上海抗癌协会传统医学专委会副主任委员',
        profilejob08:'上海市卫生经济学会常务理事',
        profilejob09:'上海市社会医疗机构协会副会长',
        profilejob10:'上海市社会医疗机构协会肿瘤分会会长',
        profilejob11:'上海市浦东新区医学会血液专委会副主任委员',
        profilejob12:'上海市浦东新区癌症康复促进会会长',
        profilehonor:'荣誉和奖项',
        profilehonor01:'上海市医学领军人才',
        profilehonor02:'上海市健康科普引领人才',
        profilehonor03:'上海市卫生系统优秀学科带头人',
        profilehonor04:'上海市卫计委重点专科肿瘤学带头人',
        profilehonor05:'上海市卫生系统第十一届“银蛇奖”',
        profilehonor06:'第五届上海市医务青年管理十杰',
        profilehonor07:'上海市卫计委首届区域名医',
        profilehonor08:'上海市十佳医生',
        profilehonor09:'上海市第三届仁心医师奖',
        profilehonor10:'上海市健康科普专家库肿瘤学和智慧健康专家',
        profilehonor11:'上海市浦东新区首届科技精英',
        profilehonor12:'上海市首届科普杰出人物',
        dev:'快速查看年份大事',
        honor01:'荣誉',
        honor02:'资质',
    },
    // 医疗健康
    medicialHealth:{
        tabList:[
            { key: 'overview', value: '整合医疗' },
            { key: 'health', value: '智慧健康' },
            { key: 'medicine', value: '精准医学' },
            { key: 'service', value: '特色服务' }
         ],
        care_title01:'服务特色',
        care_title02:'以患者为中心，多学科综合会诊（全科医师、专科医师、专家、患者、家属，线上、线下共同参与）',
        care_title03:'高品质医疗',
        care_title04:'会诊专家',
        care_title05:'护理专家',
        care_title06:'护理界“三驾马车”，为您提供社区家门口的高品质护理服务',
        care_title07:'临床科室',
        care_title08:'辅助科室',
        care_title09:'先进的医学装备',
        care_title10:'更多护理专家',
        care_title11:'更多专家',
        care_text01:'看诊时间不少于15分钟，医患充分沟通',
        care_text02:'温馨舒适的就医环境',
        care_text03:'沪上名医，为您提供社区家门口高品质医疗服务',
        smart_titlle01:'我们的使命',
        smart_titlle02:'体检套餐',
        smart_titlle05:'环境与设备',
        smart_titlle06:'专家团队',
        smart_titlle03:'构建数字化主动健康管理新模式',
        smart_titlle04:'实现疾病预防及健康管理',
        samrt_text01:' 环境整洁明亮，温馨舒适。方便的检前服务：检前评估、专属定制套餐，预约流程便捷；舒适流畅的检中服务；检后服务包括一对一报告解读、建立体检档案，根据疾病危险因素制定相关的健康促进计划，帮助个人降低危险因素，有效的预防疾病。',
        samrt_text02:'随着人口老龄化，玄合医疗开始拓展崭新业务，聚焦老年社区与家庭主动健康管理领域，开启数智健康科技发展和创新，秉持“主动预防，科技引领，健康生活”理念，致力于将最先进的科技应用于健康领域，帮助人们实现更健康、更长寿、更高质量的生活，让健康触手可及。',
        mdeicine_title01:'上海玄合元门诊部伦理委员会',
        mdeicine_title02:'上海市浦东新区东方肿瘤神经科学研究院',
        mdeicine_title03:'生物样本库',
        medicine_text01:'随着医学科技的飞速发展,越来越多的精准医学研究项目得以开展,这些项目往往涉及人类的生物样本、遗传信息以及健康数据等敏感信息。上海玄合元门诊部医学伦理委员会是一个由多学科背景的专业人员组成的独立组织。委员会包括医药专业人员、非医药专业人员、法律专家以及独立于研究单位之外的人员,以确保审查的公正性和专业性。负责对涉及人的生物医学研究项目科学性、伦理合理性进行审查。主要目的是保护受试者的尊严、安全和合法权益,促进生物医学研究规范开展。',
        medicine_text02:' 上海市东方肿瘤神经科学研究院由丁罡教授发起，成立于2023年8月16日。业务主管单位上海市浦东新区科学技术协会。研究院理事主要来自复旦大学、上海交通大学、上海市肿瘤研究所等著名学术机构。丁罡教授为研究院理事长、行政负责人，徐天乐教授、陈芳源教授为副理事长。丁罡、陈芳源、徐天乐、李锦军、朱为模、屠红、张玉秋、何士刚、王艳等9个组成研究院第一届理事会。',
        medicine_text03:'生物样本库接收各种疾病或正常的细胞、体液及组织等生物样本，为各临床、医技科室及从事基础研究、应用基础研究和临床研究的团队提供样本处理、存储等服务，并可协助提供科研设计及整体解决方案。样本库拥有超低温冰箱及大型液氮罐设备，可容纳样本100万份，样本类型可涵盖细胞、血液、组织、尿液、粪便、牙龈液、痰液等。样本库使用国内外已成熟应用的RURO系统，在管理方面可保障系统化、标准化、规范化。',
        medicine_text04:'生物样本库接收各种类型疾病、',
        medicine_text05:'正常的体液及组织等生物样本',

    },
    // 客户服务
    consumerService:{
        tabList:[
            { key: 'member', value: '会员服务' },
            { key: 'insurance', value: '商保服务' },
        ],
    },
    // 科研教学
    scienceTeach:{
        tabList:[
            { key: 'cooperation', value: '科研' },
            { key: 'teach', value: '教学' },
        
         ],
    },
    // 新闻
    mynews:{
        tabList:[
			{ key: 'dynamic', value: '玄合动态' },
			{ key: 'public', value: '社会公益' },
        ],	
    },
    // 联系我们
    connect:{
        tabList:[
            { key: 'connect', value: '联系我们' },
            { key: 'join', value: '加入我们' },	
        ],		
        connect_msg01:'上海玄合元门诊部',
        connect_msg02:'关注公众号',
        connect_msg03:'预约·挂号·查询报告',
        connect_msg04:'地址：上海市浦东新区忘忧路119号',
        connect_msg05:'电话：021-58089909',
        // {{$t('connect.join_msg01')}}
        join_msg01:'在玄合，员工是我们最珍视的财富。',
        join_msg02:'期待与您携手同行，共同成长！',
        join_msg03:'丰富多彩的团建活动',
        join_msg04:'热招职位，期待您的加入',
       
    },
    demo2: {
        name1: "托尼",
        name2: "杰克",
    },
}
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home.vue')
    },
    {
        path: '/coupe',
        name: 'coupe',
        component: () => import('../views/Coupe.vue')
    },
    {
        path: '/index',
        name: 'contact',
        component: () => import('../views/JobView.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/ProductView.vue'),
    },
    {
        path: '/product/details',
        name: 'productDetails',
        component: () => import("../views/ProductDetailsView.vue")
    },
    {
        path: '/medcialService/healthDetail',
        name: 'healthDetail',
        component: () => import("../views/medcialService/healthDetail.vue")
    },
    {
        path: '/product/details/doctor',
        name: 'doctorDetails',
        component: () => import("../views/DoctorDetailsView.vue")
    },
    {
        path: '/product/details/commision',
        name: 'commisionDetails',
        component: () => import("../views/CommisionDetailsView.vue")
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/teach',
        name: 'teach',
        component: () => import('../views/TeachView.vue')
    },
    {
        path: '/jobs',
        name: 'jobDetails',
        component: () => import('../views/JobDetailsView.vue')
    },
    {
        path: '/product/:key',
        name: 'productView',
        component: () => import('../views/ProductView.vue'),
    },
    {
        path: '/example/:key',
        name: 'exampleView',
        component: () => import('../views/ExampleView.vue')
    },
    {
        path: '/about/:key', // 动态路由参数
        name: 'aboutView',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/news/:key', // 动态路由参数
        name: 'newsView',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/contact/:key', // 动态路由参数
        name: 'contactView',
        component: () => import('../views/JobView.vue')
    },
    {
        path: '/teach/:key',
        name: 'teachView',
        component: () => import('../views/TeachView.vue')
    },
    {
        path: '/product/doactorSearch',
        name: 'doctorSearchQuery',
        component: () => import('../views/DoctorSearch.vue')
    },
    
]



const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0 
    // firefox 
    document.documentElement.scrollTop = 0 
    next() 
    }) 

export default router

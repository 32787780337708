export default {
    head:{
        hotWire: "24-hour service hotline",
        home:'home',
        about:'About',
        medicial:'Health',
        customer:'Customer service',
        teach:'Scientific',
        education:'Coupe',
        news:'NEWS',
        connect:'Contact us',
        medicial01:' Integrated Medicine ',
        medicial02:' Service Features ',
        medicial03:' Department Introduction ',
        medicial04:' Expert Introduction ',
        medicial05:' Smart Health ',
        medicial06:' Health Examination ',
        medicial07:' Medical Check-up Package ',
        medicial08:' Center for Active Health Innovation ',
        medicial09:' Precision Medicine ',
        medicial10:' Ethics Committee ',
        medicial11:' Research Institute ',
        medicial12:' Biobank ',
        medicial13:' Special Service ',
        medicial14:' Oncology Precision Personalized Comprehensive Diagnosis and Treatment Center ',
    },
    footer:{
        link_left01:' Pay attention to Xuan Heyuan Clinic public number ',
        link_left02:' Appointment, registration, inquiry report ',
        menu_item01:' About us ',
        menu_item011:'Overview',
        menu_item012:'Development',
        menu_item013:'Certification',
        menu_item02:' Health',
        menu_item021:'Medicine ',
        menu_item022:' Wisdom Health ',
        menu_item023:' Precision Medicine ',
        menu_item024:' Featured service ',
        menu_item03:' Customer Service ',
        menu_item031:' Member Service ',
        menu_item032:'Underwriters',
        menu_item04:' News Center ',
        menu_item041:'Dynamic',
        menu_item042:' Social good ',
        menu_item05:' Contact us ',
        menu_item051:' Contact us ',
        menu_item052:' Join us ',
        copyright01:' Xuanhe Medical Technology (Shanghai) Co., LTD. All Rights reserved ',
    },
    aboutUs: {
        welcome: "Hello, world",
        tabList: [
            { key: 'overview', value: 'Group' },
            { key: 'founders', value: 'Founder' },
            { key: 'development', value: 'Development' },
            { key: 'honor', value: 'Qualification' },
        ],
        intro: "Group profile",
        briefInformation: "Xuanhe Medical was established in Shanghai Pudong Zhangjiang International Medical Park on August 8, 2019. Founded by Professor Ding Gang, a famous medical expert in the field of cancer in China, adhering to the values of \"love, integrity, innovation, the pursuit of excellence, and social responsibility\", it is committed to scientific and technological innovation, management reform, service upgrading, integration of international advanced biomedical technology, medical management, Internet technology, patient education and other resources, and cooperation with a number of domestic medical institutions to build a precision cancer diagnosis and treatment center. To carry out multidisciplinary integrated precision comprehensive treatment of cancer and discipline construction and management. At the same time, a domestic patient education center was established to provide cancer prevention and treatment education for Chinese residents.",
        business: "Mature advantage business",
        businessInformation: "Dinggang Oncologist Group is the earliest and largest clinical translational medical group focusing on multidisciplinary comprehensive diagnosis and treatment of refractory tumors in China. The team focuses on specialized medical services such as tumor imaging, pathology, surgery, radiotherapy, internal medicine, minimally invasive intervention, critical care, psychology, traditional Chinese medicine, rehabilitation nursing, nutrition and clinical trials, and is good at the multidisciplinary comprehensive diagnosis and treatment of recurrent and metastatic tumors, especially the precise and personalized treatment of refractory and severe tumors.",
        health: "Proactive health innovation business",
        healthInformation:"With the aging of the population, Xuanhe Medical has begun to expand its new business and is committed to the wisdom and health lifecycle management of Chinese people, especially in the field of active health management of elderly communities and families, initiating the development and innovation of digital wisdom health technology. Adhering to the concept of \"proactive prevention, technology-led, healthy life\", the company is committed to applying the most advanced technology to the field of health, helping people achieve a healthier, longer and higher quality of life, so that health is within reach. The scope of active health innovation includes: 1. Smart wearable devices: Develop and produce smart wristbands, smart watches and other wearable devices with real-time monitoring and data analysis functions to help users understand their physical conditions in real time and prevent diseases. 2. Health management platform: Provides personalized health management programs, including suggestions and guidance on nutrition diet, exercise and fitness, mental health, etc., to help users establish a healthy lifestyle. 3. Telemedicine services: The use of the Internet and mobile medical technology to provide users with one-stop services such as online consultation, drug delivery and health consultation, so as to facilitate users to obtain medical services anytime and anywhere. 4. Community cohort study: A cohort study was conducted for chronic patients in Zhoupu Town, Pudong. Integrate medical colleges, medical institutions, biomedical enterprises, government agencies and other resources to carry out community chronic disease health management, health education, epidemiological research, prevention and intervention. Establish biomedical research platforms such as sample bank, database, cell bank and gene bank.",
        technology:'Technological innovation',
        technologyInformation:'Xuanhe Medical has always adhered to technological innovation as its core competitiveness, and has continuously invested in R&D resources to promote the innovation and development of health technology. The company has a number of domestic and foreign patents, and its technical achievements have a wide influence in the industry. On May 18, 2024, it cooperated with Montmaray County, Maryland, United States to build an international biomedical transformation center.',
        society:'Social responsibility',
        societyInformation:'Xuanhe Medical actively fulfills its social responsibilities and is committed to improving public health awareness and level. Regularly hold health science lectures and public welfare activities to popularize health knowledge and help more people establish a healthy lifestyle. At the same time, the company also pays attention to the health of vulnerable groups and provides them with help and support within their capabilities. Xuanhe Medical is the president unit of Shanghai Pudong New Area Cancer Rehabilitation Promotion Association. The vice president unit of medical services of Shanghai Cancer Rehabilitation Club.',
        future:'Future outlook',
        futureInformation:'In the future, Xuanhe Medical will continue to adhere to the concept of "active prevention, science and technology leading, healthy life", continue to innovate and develop health technology, and provide people with more convenient, efficient and personalized health services. The company will also actively expand the international market, work with global partners to promote the global development of health technology, and make greater contributions to the cause of human health.',
        company_title01:'Committed to the establishment of research-oriented, intelligent comprehensive clinic',
        company_title02:'Ding Gang Oncologist Group',
        company_title03:'Active prevention, science and technology lead, healthy life',
        company_text01:' Focused on Home and Community digital Health Innovation ',
        company_text02:' To provide people with clinical diagnosis and treatment, health management, scientific research and education, community science and other comprehensive, full-cycle health services ',
        company_text03:' Focus on oncology imaging, pathology, surgery, radiotherapy, internal medicine, minimally invasive interventions, ',
        company_text04:' Specialized medical services such as intensive care, psychology, traditional Chinese medicine, rehabilitation care, nutrition and clinical trials ',
        company_text05:' Specialized in multidisciplinary integrated, accurate and personalized diagnosis and treatment of refractory, severe, relapsed and metastatic tumors ',
        company_text06:' Applying the most advanced technology to health ',
        company_text07:' Help people live healthier, longer, higher quality lives ',
        company_doctor01:' Chief physician ',
        company_doctor02:' Full-time specialist health care worker ',
        company_doctor03:' Part-time oncologist ',
        profile:' Ding Gang Chief Scientist, Founder of Xuanhe Medical ',
        profile_dec01:' Doctor of Medical Sciences, Chief Physician, Professor ',
        profile_dec02:' Doctoral Supervisor of Shanghai Jiaotong University ',
        profile_dec03:'CEIBS EMBA',
        profile_dec04: "Master Tutor of Health Administration, Nanjing Medical University",
        profile_dec05:'25 years of clinical work in cancer department, 15 years of hospital management positions. ',
        profile_dec06: "Director and Deputy Director of Oncology Department of Fudan Cancer Hospital, Renji Hospital, Xinhua Hospital and Shanghai International Medical Center",
        profileadv:' good at ',
        profileadv01:' Good at the development of personalized precision diagnosis and treatment plans for common tumor diseases, especially the comprehensive treatment of tumor recurrence and metastasis and difficult severe diseases. ',
        profileadv02:' Good at discipline construction and comprehensive management of hospitals. ',
        profilemain:' Major work performance ',
        profilemain01:' Exploring new mechanisms of disease development: It is the first time in the world to propose and verify the hypothesis of "nerve → tumor" and clarify the psychophysiological mechanism of this process. The first innovative research in the field of tumor neuroregulation in the world. ',
        profilemain02:' Creating a new pathway for clinical cancer diagnosis and treatment. To create a new model of multi-disciplinary, precise and personalized diagnosis and treatment of refractory tumors. ',
        profilemain03:" Create '5H innovative medical service model for cancer patients' (professional Hospital, comfortable Hotel, warm Home, Heart and heart, Happiness and satisfaction). Won the nomination award of Shanghai Medical Service Brand and the fifth 'Shanghai Top Ten Innovative Measures of Medical Reform' in 2022. ",
        profilemain04:' Actively carry out science popularization public welfare projects, and won the first round of Shanghai Health Science Leading Talents Special project in 2022. ',
        profilejob:' Academic employment ',
        profilejob01:' Deputy Director of Rehabilitation Branch of Chinese Anti-Cancer Association ',
        profilejob02: 'Vice President of Health Management Branch of China Medical Equipment Association',
        profilejob03:' Director of Chinese Neuroscience Society. Deputy Director, Sensory and Motor Branch ',
        profilejob04:' Vice Chairman of the Pain Transformation Research Committee of the Chinese Physiological Society ',
        profilejob05: 'Vice President of Social Branch of Chinese Research Hospital Society',
        profilejob06: "Member of the Standing Committee of Cancer Branch of Shanghai Medical Doctors Association, Secretary",
        profilejob07:' Deputy Director of the Traditional Medicine Committee of Shanghai Anti-Cancer Association ',
        profilejob08: "Executive Director of Shanghai Health Economics Association",
        profilejob09: "Vice President of Shanghai Association of Social Medical Institutions",
        profilejob10: "President of Cancer Branch of Shanghai Association of Social Medical Institutions",
        profilejob11: "Deputy Director of the Blood Special Committee of Shanghai Pudong New Area Medical Association",
        profilejob12: "President of Shanghai Pudong New Area Cancer Rehabilitation Promotion Association",
        profilehonor:' Honors and awards ',
        profilehonor01: "Leading Medical Talents in Shanghai",
        profilehonor02: "Shanghai Health Science Leading Talents",
        profilehonor03: "Excellent Discipline Leader of Shanghai Health System",
        profilehonor04: "Leader of Oncology in Key Specialties of Shanghai Municipal Health and Family Planning Commission",
        profilehonor05: "The 11th Silver Snake Award of Shanghai Health System",
        profilehonor06:' The Fifth Shanghai Top Ten Medical Youth Management ',
        profilehonor07: "The first Regional Famous Doctor of Shanghai Health and Family Planning Commission",
        profilehonor08: "Top 10 Doctors in Shanghai",
        profilehonor09: "The Third Shanghai Kindhearted Doctor Award",
        profilehonor10: "Shanghai Health Science Expert Database Oncology and Smart health experts",
        profilehonor11:' The First Science and Technology Elite of Shanghai Pudong New Area ',
        profilehonor12: "Shanghai's first science popularization Outstanding Figure",
        dev:' Quick look at year events ',
        honor01:' Honor ',
        honor02:' Qualification ',  
    },
    medicialHealth:{
        tabList:[
            {key: 'overview', value: 'Integrated care'},
            {key: 'health', value: 'smart health'},
            {key: 'medicine', value: 'precision medicine'},
            {key: 'service', value: 'special service'}
        ],
        care_title01:' Service Features ',
        care_title02:' patient-centered, multidisciplinary consultation (general practitioners, specialists, specialists, patients, families, online and offline participation) ',
        care_title03:' High Quality Healthcare ',
        care_title04:' Consultant ',
        care_title05:' Nursing Specialist ',
        care_title06:" The 'three carriages' of China's nursing industry, providing you with high-quality nursing services at the doorstep of the community ",
        care_title07:' Clinical Department ',
        care_title08:' Auxiliary Department ',
        care_title09:' Advanced medical equipment ',
        care_title10:' More Nursing Specialists ',
        care_title11:' More experts ',
        care_text01:' No less than 15 minutes of consultation, full communication between doctors and patients ',
        care_text02:' Warm and comfortable medical environment ',
        care_text03:' Shanghai famous doctor, to provide you with high-quality medical services at the doorstep of the community ',
        smart_titlle01:' Health Check-up ',
        smart_titlle02:' Medical Check-up Package ',
        smart_titlle03:' Building a new Model of Digital Active Health Management ',
        smart_titlle04:' To achieve disease prevention and health management ',
        samrt_text01:' The environment is clean, bright, warm and comfortable. Convenient pre-inspection service: pre-inspection evaluation, exclusive customized packages, convenient booking process; Comfortable and smooth inspection service; Post-examination services include one-to-one report interpretation, establishment of physical examination files, and formulation of relevant health promotion plans according to disease risk factors to help individuals reduce risk factors and effectively prevent diseases. ',
        samrt_text02:' With the aging of the population, Xuanhe Medical began to expand new business, focusing on the field of active health management of elderly communities and families, opening the development and innovation of digital health technology, adhering to the concept of "active prevention, technology leadership, healthy life", and committed to applying the most advanced technology to the health field. Help people live healthier, longer, better lives, and make health accessible. ',
        mdeicine_title01:' Ethics Committee of Shanghai Xuanheyuan Outpatient Department ',
        mdeicine_title02: "Shanghai Pudong New Area Oriental Cancer Neuroscience Institute",
        mdeicine_title03:' Biobank ',
        medicine_text01:' With the rapid development of medical science and technology, more and more precision medicine research projects have been carried out, which often involve sensitive information such as human biological samples, genetic information and health data. The Medical Ethics Committee of Shanghai Xuanheyuan Outpatient Department is an independent organization composed of professionals with multidisciplinary backgrounds. The committee includes medical professionals, non-medical professionals, legal experts, and persons independent of the research unit to ensure the impartiality and professionalism of the review. Responsible for reviewing the scientific and ethical reasonableness of biomedical research projects involving human beings. The main purpose is to protect the dignity, safety and legitimate rights and interests of the subjects and promote the standardized development of biomedical research. ',
        medicine_text02:' Shanghai Oriental Cancer Neuroscience Institute was founded by Professor Ding Gang on August 16, 2023. Business supervisor Shanghai Pudong New Area Science and Technology Association. The directors of the institute are mainly from Fudan University, Shanghai Jiao Tong University, Shanghai Cancer Research Institute and other famous academic institutions. Professor Ding Gang is the chairman and administrative director of the Institute, and Professor Xu Tianle and Professor Chen Fangyuan are the vice chairmen. Ding Gang, Chen Fangyuan, Xu Tianle, Li Jinjun, Zhu Weimo, Tu Hong, Zhang Yuqiu, He Shigang, Wang Yan and other 9 formed the first council of the institute. ',
        medicine_text03:' The biobank receives biological samples of various diseases or normal cells, body fluids and tissues, providing sample processing, storage and other services for clinical and medical technical departments and teams engaged in basic research, applied basic research and clinical research, and assisting in providing scientific research design and overall solutions. The sample bank has ultra-low temperature refrigerator and large liquid nitrogen tank equipment, which can hold 1 million samples, including cell, blood, tissue, urine, stool, gum fluid, sputum and so on. The sample library uses the RURO system which has been mature at home and abroad, which can guarantee systematization, standardization and standardization in management. ',
        medicine_text04:' Biobank receives all types of diseases, ',
        medicine_text05:' Normal biological samples such as body fluids and tissues ',
    },
    // 客户服务
    consumerService:{
        tabList:[
            {key: 'member', value: 'Member service'},
            {key: 'insurance', value: 'underwriters'},
        ],
    },
    // 科研教学
    scienceTeach:{
        tabList:[
            {key: 'dynamic', value: 'metaphysical dynamic'},
            {key: 'public', value: 'social good'},
        ],
    },
     // 新闻
     mynews:{
        tabList:[
            {key: 'dynamic', value: 'metaphysical dynamic'},
            {key: 'public', value: 'social good'},
            ],
    },
    // 联系我们
    connect:{
        tabList:[
            {key: 'connect', value: 'Contact us'},
            {key: 'join', value: 'Join us'},
        ],
        connect_msg01:' Shanghai Xuanheyuan Outpatient Department ',
        connect_msg02:' Concern public number ',
        connect_msg03:' Appointment, Registration, Inquiry Report ',
        connect_msg04:' Address: No. 119, Lotus Road, Pudong New Area, Shanghai ',
        connect_msg05:' Tel: 021-58089909',
        join_msg01:' At Genhe, employees are our most cherished asset. ',
        join_msg02:' Looking forward to working with you hand in hand and growing together! ',
        join_msg03:' Colorful Group Building Activities ',
        join_msg04:' Hot recruitment position, looking forward to your joining ',
    },
    demo2: {
        name1: "toni",
        name2: "jieKe",
    },
}
<template>
  <div class="memberService">
    <div class="box_item" style="padding: 20px 0 60px 0;" v-for="(item,index) in clientInfo" :key="index">
      <div class="member-box">
      <div class="service-border">
          <img src="../../assets/images/medcialService/health/normal_u585.svg" alt="">
        </div>
        <div class="medcialService-title">
          <p style="text-align: center;">{{ item.cardName }}</p>
        </div>
      <div class="memberService-content">
        <div class="memberService-main" v-if="MobileVerification">
          {{ item.cardDesc }}
        </div>
        <div class="memberService-img" style="background-image: linear-gradient(-180deg, #958eff 0%, #958eff 7.999999999999995%, #6136cf 97.99999999999996%, #6136cf 99.99999999999996%);">
          <img :src="item.fileUrl " alt="">
        </div>
        <div class="memberService-main" v-if="!MobileVerification">
          {{ item.cardDesc }}
        </div>
      </div>
      <el-collapse>
        <el-collapse-item>
          <template slot="title">
            <div class="box-title">
              <img class="svg-icon" :src="`${ imgUrl }/activity/physical.svg`"  alt="">
              <p class="common">套餐详情</p>
            </div>        
          </template>
          <el-table
            :data="item.detailList"
            border
            style="width: 100%;">
          <el-table-column
              prop="serviceItem"
              label="服务项目"
              align="center"
              width="180">
          </el-table-column>
          <el-table-column
              prop="serviceContent"
              align="left"
              label="服务内容">
          </el-table-column>
        </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/api/api";
import { EventBus } from '../../utils/enevt-bus';
export default {
    name:'memberService',
    
    data() {
    return {
      dialogTableVisible:false,
      tableData: [{
        serviceItem: '年度体检',
        serviceContent: '私人定制年度体检计划，高端医疗机构年度体检一次，专业医生检前问诊，根据个体情况个性化定制体检项目（加项另外收费），面对面解读体检报告',
      }, {
        serviceItem: '家庭医生',
        serviceContent: '配备1位专属家庭医生，制定全家人健康管理方案，通过可穿戴健康设备，实时采集跟踪健康数据，给予健康指导及疾病治疗',
      }, {
        serviceItem: '专科医生',
        serviceContent: '配备心内科、神经内科、呼吸科、内分泌科、消化科、妇科、中医科等专科医生，负责疾病的诊治 ',
      }, {
        serviceItem: '健康管家',
        serviceContent: '配备1位专属健康管家，制定家庭成员个性化健康管理方案，定期跟踪随访，复查提醒、用药提醒等',
      }, {
        serviceItem: '三甲绿通',
        serviceContent: '协助预约沪上各大三甲医院专家（指定专家或家庭医生推荐专家），三天内实现就诊（诊费及相关检查费用另外支付）',
      }, {
        serviceItem: '在线咨询',
        serviceContent: '7*8H在线守护，包括图文问诊，健康咨询、用药指导等',
      }, {
        serviceItem: '居家服务',
        serviceContent: '护理人员定期上门，为您和家人测量血压、血脂、伤口换药等，必要时提供上门采血、上门送药等服务',
      }, {
        serviceItem: '穿戴设备',
        serviceContent: '配备个人可穿戴设备，采集动态健康数据并时时上传，协助医生动态掌握您的健康状况',
      }, {
        serviceItem: '家庭药箱',
        serviceContent: '配备家庭药箱，提供家庭必须医疗用品（含常备基础应急用品十余种），OTC药物3种，定期上门更新药箱',
      }, {
        serviceItem: '核磁检查',
        serviceContent: '头颅血管磁共振检测',
      }, {
        serviceItem: '专车接送',
        serviceContent: '专车司机接送就诊，保您全程无驾车烦忧',
      }, {
        serviceItem: '折扣优惠',
        serviceContent: '享受玄合元门诊部就诊折扣',
      }
      ],
      clientInfo:[],
      imgUrl:process.env.VUE_APP_IMG_BASE_URL,
      language:'',
    };
  },
  created() {
      EventBus.$on('globalComponentChanged', this.handleGlobalComponentChange);
  },
  watch: {
		MobileVerification: {
			handler(to) {
			//处理函数
				// this.isMobileVt();
			},
			immediate: true,
		},
	},
  mounted(){
    this.language = this.$i18n.locale
    this.getMemberData();
  },
  methods:{
    handleGlobalComponentChange(data) {
        this.language = data.someData
        this.getMemberData()
      },
    getMemberData () {
      let headers = { 'Accept-Language': this.language === 'en' ? 'en_US' : 'zh_CN' }

      getRequest('/api/record/membersCard/list',{},headers).then((res) => {
        console.log('会员卡列表',res.data.data)
        this.clientInfo = res.data.data
      });
    },
    openModal(){
      console.log('打开弹窗')
    },
  }
}
</script>

<style scoped lang="less">
.memberService{
  min-height: 700px;
}
.el-drawer__wrapper{
  z-index: 99999 !important;
}
.el-table{
  font-size: 20px;
  color: #000 !important;
}
.el-table thead{
  color: #000;
}
.service-border{
  display: flex;
  align-items: center;
  justify-content: center;
}
.medcialService-title{
  margin-bottom: 40px;
}
.box-title{
    width: 1240px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .common{
      font-size: 16px;
      font-family: "PingFangSC-Medium", "PingFang SC Medium", "PingFang SC", sans-serif;
      color: #333333;
    }
    .svg-icon{
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
</style>
//混入，实时获取宽高并把判断是否是手机端，需要在调用的地方+watch
export default {
	data() {
		return {
			windowWidth: window.innerWidth, //视窗宽度
			windowHeight: window.innerHeight, //视窗高度
			MobileVerification: window.matchMedia('(max-width: 900px)').matches, //是否是手机端
			eduServerAddress: window.$eduServerAddress, //挂载全局链接
			indexServerAddress: window.$indexServerAddress, //挂载全局链接
		};
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			//媒体查询写法
			const isMobile = window.matchMedia('(max-width: 900px)').matches;
			this.MobileVerification = isMobile;
			//bom写法
			// this.MobileVerification =
			// 	window.innerWidth <= 769 ||
			// 	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			// 		navigator.userAgent
			// 	);
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
	},
};


import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";
import i18n from "@/langue/i18n";
import MetaInfo from 'vue-meta-info';
import windowSizeMixin from '@/utils/windowSizeMixin';//混入-浏览器窗口监听
import scroll from 'vue-seamless-scroll'

Vue.mixin(windowSizeMixin);
Vue.prototype.getRequest = getRequest;
Vue.use(ElementUI);
Vue.use(MetaInfo);
Vue.use(scroll)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App),
  // 这句非常重要，否则预渲染将不会启动
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

// 引入 axios
import axios from "axios";

let base = process.env.VUE_APP_API_BASE_URL;
//传送json格式的get请求
export const getRequest=(url,params,header)=>{
    return axios({
        headers: header,
        method:'get',
        params:params,
        url:`${base}${url}`,
    })
}
